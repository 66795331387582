import { mergeDeepRight, reduce } from "ramda";
import general from "./generalTranslations";
import frontend from "../frontend/frontendTranslations";
import backend from "../backend/backendTranslations";
import dashboard from "../backend/dashboard/dashboardTranslations";
import onboarding from "../backend/onboarding/onboardingTranslations";
import organization from "../backend/organisation/organizationTranslation";
import editor from "../backend/editor/editorTranslations";
import sessions from "../backend/sessions/sessionTranslations";
import users from "../backend/users/userTranslations";
import auth from "../frontend/auth/authTranslations";

const mergeDeepAll = reduce(mergeDeepRight, {});

export default mergeDeepAll([
  general,
  frontend,
  backend,
  dashboard,
  onboarding,
  organization,
  editor,
  sessions,
  users,
  auth,
]);
