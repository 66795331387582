import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Auth, I18n } from "aws-amplify";
import { Button, Menu } from "antd";
import {
  HomeOutlined,
  GlobalOutlined,
  SettingOutlined,
  PoweroffOutlined,
  UserOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { StateContext } from "../state";
import { AbilityContext } from "../config/Can";

export default function Navigation_Frontend({ color }) {
  const history = useHistory();
  const { setLanguage, isAuthenticated } = useContext(StateContext);
  const ability = useContext(AbilityContext);

  return (
    <Menu
      mode="horizontal"
      style={{
        background: "transparent",
        color: color,
        textAlign: "right",
        borderBottom: "none",
      }}
    >
      <Menu.Item onClick={() => history.push("/")}>
        <HomeOutlined />
        Home
      </Menu.Item>
      {ability.can("view", "/backend") && (
        <Menu.Item onClick={() => history.push("/backend")}>
          <SettingOutlined />
          Backend
        </Menu.Item>
      )}
      <Menu.SubMenu
        title={
          <span className="submenu-title-wrapper">
            <GlobalOutlined />
            Language
          </span>
        }
      >
        <Menu.Item onClick={() => setLanguage("en")}>
          {I18n.get("general english")}
        </Menu.Item>
        <Menu.Item onClick={() => setLanguage("de")}>
          {I18n.get("general german")}
        </Menu.Item>
      </Menu.SubMenu>
      {isAuthenticated && (
        <Menu.Item onClick={() => history.push("/profile")}>
          <UserOutlined />
          Profile
        </Menu.Item>
      )}
      {isAuthenticated ? (
        <Menu.Item
          onClick={() => {
            Auth.signOut();
            history.push("/");
          }}
        >
          <PoweroffOutlined />
          Logout
        </Menu.Item>
      ) : (
        <Menu.Item onClick={() => history.push("/signin")}>
          <Button type="primary">
            <LoginOutlined />
            Signup/Signin
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );
}
