/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      platformTitle {
        en
        de
      }
      editorStateDE
      editorStateEN
      website {
        en
        de
      }
      contact
      logo {
        en
        de
      }
      primaryColor
      secondaryColor
      font
      backgroundImage
      standardCover
      welcomeText {
        en
        de
      }
      events {
        items {
          id
          state
          image
          start
          end
          template
          count
          isRestricted
          group
          audience
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      departments {
        items {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        nextToken
      }
      features
      bbbServerUrl
      bbbServerSecret
      currentSessionDesc {
        en
        de
      }
      upcomingSessionDesc {
        en
        de
      }
      archivedSessionDesc {
        en
        de
      }
      copyright
      maxEvents
      transactionalEmail
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        departments {
          nextToken
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      primaryColor
      editorStateDE
      editorStateEN
      modules {
        items {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        nextToken
      }
      logo
      website
      staff {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        departments {
          nextToken
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        createdAt
        updatedAt
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      programs {
        items {
          id
          editorStateDE
          editorStateEN
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        modules {
          nextToken
        }
        logo
        website
        staff {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProgram = /* GraphQL */ `
  query GetProgram($id: ID!) {
    getProgram(id: $id) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      editorStateDE
      editorStateEN
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        modules {
          nextToken
        }
        logo
        website
        staff {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPrograms = /* GraphQL */ `
  query ListPrograms(
    $filter: ModelProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        editorStateDE
        editorStateEN
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      state
      title {
        en
        de
      }
      description {
        en
        de
      }
      image
      start
      end
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        departments {
          nextToken
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        createdAt
        updatedAt
      }
      enrollments {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      template
      count
      isRestricted
      group
      audience
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        image
        start
        end
        modules {
          nextToken
        }
        sessions {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        template
        count
        isRestricted
        group
        audience
        likes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventEnrollments = /* GraphQL */ `
  query GetEventEnrollments($id: ID!) {
    getEventEnrollments(id: $id) {
      id
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        image
        start
        end
        modules {
          nextToken
        }
        sessions {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        template
        count
        isRestricted
        group
        audience
        likes {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const listEventEnrollmentss = /* GraphQL */ `
  query ListEventEnrollmentss(
    $filter: ModelEventEnrollmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventEnrollmentss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event {
          id
          state
          image
          start
          end
          template
          count
          isRestricted
          group
          audience
          createdAt
          updatedAt
        }
        user {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProgramSession = /* GraphQL */ `
  query GetProgramSession($id: ID!) {
    getProgramSession(id: $id) {
      id
      program {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        editorStateDE
        editorStateEN
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
        createdAt
        updatedAt
      }
      session {
        id
        event {
          id
          state
          image
          start
          end
          template
          count
          isRestricted
          group
          audience
          createdAt
          updatedAt
        }
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        modules {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        messages {
          nextToken
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        ratings {
          nextToken
        }
        isProtected
        password
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        likes {
          nextToken
        }
        sessionDepartmentId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProgramSessions = /* GraphQL */ `
  query ListProgramSessions(
    $filter: ModelProgramSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgramSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        program {
          id
          editorStateDE
          editorStateEN
          createdAt
          updatedAt
        }
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        image
        start
        end
        modules {
          nextToken
        }
        sessions {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        template
        count
        isRestricted
        group
        audience
        likes {
          nextToken
        }
        createdAt
        updatedAt
      }
      title {
        en
        de
      }
      description {
        en
        de
      }
      start
      end
      language
      type
      sessionState
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        departments {
          nextToken
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        createdAt
        updatedAt
      }
      enrollments {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          body
          createdAt
          sessionId
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        modules {
          nextToken
        }
        logo
        website
        staff {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      program {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      stream
      chatEnabled
      conference
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        sections {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        color
        users {
          nextToken
        }
        likes {
          nextToken
        }
        isPublished
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        sort
        rating {
          nextToken
        }
      }
      isFree
      audience
      color
      resources {
        description {
          en
          de
        }
        link
      }
      image
      anonymous
      isRated
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      isProtected
      password
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      zoom {
        start_url
        join_url
        id
      }
      teams {
        joinUrl
        id
      }
      count
      isRestricted
      group
      link
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessionDepartmentId
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event {
          id
          state
          image
          start
          end
          template
          count
          isRestricted
          group
          audience
          createdAt
          updatedAt
        }
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        modules {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        messages {
          nextToken
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        ratings {
          nextToken
        }
        isProtected
        password
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        likes {
          nextToken
        }
        sessionDepartmentId
      }
      nextToken
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      stars
      text
      session {
        id
        event {
          id
          state
          image
          start
          end
          template
          count
          isRestricted
          group
          audience
          createdAt
          updatedAt
        }
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        modules {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        messages {
          nextToken
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        ratings {
          nextToken
        }
        isProtected
        password
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        likes {
          nextToken
        }
        sessionDepartmentId
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        sections {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        color
        users {
          nextToken
        }
        likes {
          nextToken
        }
        isPublished
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        sort
        rating {
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stars
        text
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        user {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAuthEvent = /* GraphQL */ `
  query GetAuthEvent($id: ID!) {
    getAuthEvent(id: $id) {
      id
      type
      createdAt
      user {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listAuthEvents = /* GraphQL */ `
  query ListAuthEvents(
    $filter: ModelAuthEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        user {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      body
      createdAt
      session {
        id
        event {
          id
          state
          image
          start
          end
          template
          count
          isRestricted
          group
          audience
          createdAt
          updatedAt
        }
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        modules {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        messages {
          nextToken
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        ratings {
          nextToken
        }
        isProtected
        password
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        likes {
          nextToken
        }
        sessionDepartmentId
      }
      sessionId
      author {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      replies {
        id
        body
        createdAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        sessionId
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        replies {
          id
          body
          createdAt
          sessionId
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        likes {
          nextToken
        }
        updatedAt
      }
      isFlagged
      flaggedBy
      isRepliedTo
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        body
        createdAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        sessionId
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        replies {
          id
          body
          createdAt
          sessionId
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        likes {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getModule = /* GraphQL */ `
  query GetModule($id: ID!) {
    getModule(id: $id) {
      id
      title {
        en
        de
      }
      startpage {
        en
        de
      }
      startpageEN
      startpageDE
      sections {
        items {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        modules {
          nextToken
        }
        logo
        website
        staff {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      color
      users {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      isPublished
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      sort
      rating {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listModules = /* GraphQL */ `
  query ListModules(
    $filter: ModelModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        sections {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        color
        users {
          nextToken
        }
        likes {
          nextToken
        }
        isPublished
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        sort
        rating {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        sections {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        color
        users {
          nextToken
        }
        likes {
          nextToken
        }
        isPublished
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        sort
        rating {
          nextToken
        }
      }
      title {
        en
        de
      }
      description {
        en
        de
      }
      startpage {
        en
        de
      }
      pages {
        items {
          id
          editorStateDE
          editorStateEN
          sort
          isPublished
          createdAt
          updatedAt
          count
        }
        nextToken
      }
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        title {
          en
          de
        }
        description {
          en
          de
        }
        startpage {
          en
          de
        }
        pages {
          nextToken
        }
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      section {
        id
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        title {
          en
          de
        }
        description {
          en
          de
        }
        startpage {
          en
          de
        }
        pages {
          nextToken
        }
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
      }
      title {
        en
        de
      }
      editorState {
        en
        de
      }
      editorStateDE
      editorStateEN
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      count
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        section {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        title {
          en
          de
        }
        editorState {
          en
          de
        }
        editorStateDE
        editorStateEN
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        count
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      image
      userType
      messages {
        items {
          id
          body
          createdAt
          sessionId
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      position {
        en
        de
      }
      departments {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      isTemporary
      onboarded
      modules {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      authEvents {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      ytTokens {
        accessToken
        refreshToken
        expires_in
      }
      teamsTokens {
        accessToken
        refreshToken
        expires_in
      }
      redirect
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSessionEnrollment = /* GraphQL */ `
  query GetSessionEnrollment($id: ID!) {
    getSessionEnrollment(id: $id) {
      id
      session {
        id
        event {
          id
          state
          image
          start
          end
          template
          count
          isRestricted
          group
          audience
          createdAt
          updatedAt
        }
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        modules {
          nextToken
        }
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        messages {
          nextToken
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        ratings {
          nextToken
        }
        isProtected
        password
        createdAt
        updatedAt
        author {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        likes {
          nextToken
        }
        sessionDepartmentId
      }
      user {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      type
      sessionEnrollmentSessionId
      sessionEnrollmentUserId
      createdAt
      updatedAt
    }
  }
`;
export const listSessionEnrollments = /* GraphQL */ `
  query ListSessionEnrollments(
    $filter: ModelSessionEnrollmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionEnrollments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        user {
          id
          firstName
          lastName
          email
          image
          userType
          isTemporary
          onboarded
          redirect
          createdAt
          updatedAt
        }
        type
        sessionEnrollmentSessionId
        sessionEnrollmentUserId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        image
        userType
        messages {
          nextToken
        }
        sessions {
          nextToken
        }
        position {
          en
          de
        }
        departments {
          nextToken
        }
        likes {
          nextToken
        }
        isTemporary
        onboarded
        modules {
          nextToken
        }
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
