export default {
  de: {
    "users temporary new password": "Neues temporäres Passwort",
    "users password policy":
      "Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Nummer beinhalten",
    "users notify": "Passwort via E-Mail senden",
  },
  en: {
    "users temporary new password": "Temporary new passwort",
    "users password policy":
      "Password needs to include uppercase letters, lowercase letters and numbers",
    "users notify": "Send user password via email",
  },
};
