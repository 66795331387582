import React, { useContext, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import { Form, Input, Button, Typography, notification } from "antd";
import { StateContext } from "../../state";

const { Title, Paragraph } = Typography;

const data = {
  forgotPasswordTitle: {
    en: "Forgot password",
    de: "Passwort vergessen",
  },
  forgotPasswordButton: {
    en: "Reset password",
    de: "Passwort zurücksetzen",
  },
  requiredMessage: {
    en: "Required field",
    de: "Pflichtfeld",
  },
  newPassword: {
    en: "New Password",
    de: "Neues Passwort",
  },
  codeSent: {
    en: "We have sent you a confirmation code. Please check your emails.",
    de:
      "Wir haben Ihnen einen Code zugeschickt. Bitte checken Sie Ihre Emails.",
  },
};

export default function ForgotPasswordForm(props) {
  const [username, setUsername] = useState(false);
  const { language } = useContext(StateContext);
  const {
    forgotPasswordTitle,
    forgotPasswordButton,
    requiredMessage,
    newPassword,
    codeSent,
  } = data;

  const onFinish = async (values) => {
    try {
      await Auth.forgotPassword(values.email.toLowerCase());
      setUsername(values.email.toLowerCase());
    } catch (err) {
      notification.error({ message: "Error", description: err.message });
    }
  };

  const submitNewPassword = async (values) => {
    try {
      await Auth.forgotPasswordSubmit(username, values.code, values.password);
      notification.success({
        message: "Success",
        description: "Successfully reset your password",
      });
      setUsername(false);
      props.onStateChange("signIn");
    } catch (err) {
      notification.error({ message: "Error", description: err.message });
    }
  };

  return (
    <>
      {props.authState === "forgotPassword" ? (
        <Wrapper>
          <>
            {username ? (
              <>
                <Title level={3}>{forgotPasswordTitle[language]}</Title>
                <Paragraph>{codeSent[language]} </Paragraph>
                <Form
                  name="confirmPasswordCodeForm"
                  layout="vertical"
                  onFinish={submitNewPassword}
                >
                  <Form.Item
                    label={"Code"}
                    name="code"
                    rules={[
                      { required: true, message: requiredMessage[language] },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    label={newPassword[language]}
                    name="password"
                    rules={[
                      { required: true, message: requiredMessage[language] },
                    ]}
                  >
                    <Input type="password" />
                  </Form.Item>
                  <Button htmlType="submit" type="primary">
                    {forgotPasswordButton[language]}
                  </Button>
                </Form>
              </>
            ) : (
              <>
                <Title level={3}>{forgotPasswordTitle[language]}</Title>
                {/* <Paragraph>
          {data.login[language]}{" "}
        </Paragraph> */}
                <Form
                  name="confirmPasswordForm"
                  layout="vertical"
                  onFinish={onFinish}
                >
                  <Form.Item
                    label={"Email"}
                    name="email"
                    rules={[
                      { required: true, message: requiredMessage[language] },
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ marginBottom: "1rem" }}
                  >
                    {forgotPasswordButton[language]}
                  </Button>
                  <br />
                  <Button
                    size="small"
                    onClick={() => props.onStateChange("signIn")}
                  >
                    {language === "en" ? "Back" : "Zurück"}
                  </Button>
                </Form>
              </>
            )}
          </>
        </Wrapper>
      ) : null}
    </>
  );
}

const Wrapper = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 1rem;
`;
