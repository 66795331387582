export default {
  de: {
    "sessions activate": "Aktivieren",
    "sessions archive": "Archivieren",
    "sessions publish": "Publizieren",
    "sessions unpublish": "Verstecken",
    "sessions change state": "Status ändern",
    "sessions chat enabled": "Chat aktiviert",
    "session link placeholder":
      "Wenn Sie keinen Link einfügen, wird das Standard-Video Ihrer Organisation angezeigt.",
    "session change later": "Sie können dies später noch ändern.",
    "session anonymous": "Zugang für nicht-registrierte User",
    "session anonymous help":
      "Wenn Sie diese Option wählen, können User auch ohne eine Registrierung an der Session teilnehmen und bleiben dabei anonym.",
    "session rating": "Bewertung",
    "session rating desc": "Bewertungen von Teilnehmenden zulassen.",
    "session feedback": "Feedback",
    "session feedback desc":
      "Bitte teilen Sie uns mit, wie Ihnen diese Session gefallen hat.",
    "session rating comment": "Anmerkungen",
    "session restrict access": "Zugang für Gruppen beschränken",
    "session upcoming": "Kommende Sessions",
    "session current": "Laufende Sessions",
    "session archive": "Archivierte Sessions",
    "session link conference":
      "Geben Sie den Link zu Ihrer Conference an (Zoom, Teams, etc.)",
    "session zoom terms":
      "Mit der Teilnahme stimmen Sie den Nutzungsbedingungen für Zoom zu.",
    "session active": "Aktiv",
    "session scheduled": "Publiziert",
    "session archived": "Archiviert",
    "session unpublished": "Versteckt",
    "session select to format":
      "Text markieren um Formatierungsoptionen zu sehen.",
  },

  en: {
    "sessions activate": "Activate",
    "sessions archive": "Archive",
    "sessions publish": "Publish",
    "sessions unpublish": "Unpublish",
    "sessions change state": "Change State",
    "sessions chat enabled": "Chat enabled",
    "session link placeholder":
      "If you do not specify a link, the standart placeholder video of your organization will be added.",
    "session change later": "You may change this property later.",
    "session anonymous": "Access for non-registered users",
    "session anonymous help":
      "If you choose this option, non-registered users can access. These users will remain anonymous throughout the session.",
    "session rating": "Rating",
    "session rating desc": "Allow users to leave ratings",
    "session feedback": "Feedback",
    "session feedback desc": "Please tell us how you liked this session.",
    "session rating comment": "Comments",
    "session restrict access": "Restrict Access for Groups",
    "session upcoming": "Upcoming Sessions",
    "session current": "Current Sessions",
    "session archive": "Archived Sessions",
    "session link conference":
      "Provide the link to your conference (e.g. Zoom, Teams, etc.)",
    "session zoom terms":
      "By joining this event you agree to the terms of use of Zoom.",
    "session active": "Active",
    "session scheduled": "Published",
    "session archived": "Archived",
    "session unpublished": "Unpublished",
    "session select to format": "Select text to see formatting options.",
  },
};
