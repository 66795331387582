const translations = {
  de: {
    "frontend welcome": "Willkommen",
  },
  en: {
    "frontend welcome": "Welcome",
  },
};

export default translations;
