export default {
  de: {
    "editor cover info":
      "Wenn Sie kein Cover hochladen, wird das Standard-Cover Ihrer Organisation verwendet",
    "editor unsaved prompt":
      "Beim Verlassen der Seite gehen alle ungespeicherte Inhalte verloren. Möchten Sie fortfahren?",
  },
  en: {
    "editor cover info":
      "If you do not upload a cover image, the default cover of your organization will be used.",
    "editor unsaved prompt":
      "All unsaved changes will be lost once you leave this page. Do you want to continue?",
  },
};
