import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AbilityContext } from "./config/Can";
import ability from "./config/ability";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.less";
import "./index.css";

import App from "./App";
import GlobalErrorBoundary from "./GlobalErrorBoundary";
import Loader from "./Loader";
import Routes from "./Routes";
import DepartmentProvider from "./providers/DepartmentProvider";

ReactDOM.render(
  // <BrowserRouter>
  //   <AbilityContext.Provider value={ability}>
  //     <GlobalErrorBoundary>
  //       <DepartmentProvider>
  //         <App>
  //           <Suspense fallback={<Loader />}>
  //             <Routes />
  //           </Suspense>
  //         </App>
  //       </DepartmentProvider>
  //     </GlobalErrorBoundary>
  //   </AbilityContext.Provider>
  // </BrowserRouter>,
  <div>Not available</div>,
  document.getElementById("root")
);

serviceWorker.unregister();
