export default {
  de: {
    "auth register success": "Registrierung erfolgreich",
    "auth resend success": "Code erneut gesendet",
    "auth resend code": "Code erneut schicken",
  },
  en: {
    "auth register success": "Successfully registered your account",
    "auth resend success": "Successfully resent your code",
    "auth resend code": "Resend code",
  },
};
