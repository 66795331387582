/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:749ec09a-fbe6-45c9-b4fb-99899174753b",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Cc5bEGziL",
    "aws_user_pools_web_client_id": "n5886ua4nibvosc712s41hhjr",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://x4xf7sxv2vdqzff6odbiyi2svi.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vipnlt4y35bqxac6vul46o5dba",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://wmtjkogjl0.execute-api.eu-central-1.amazonaws.com/mdprod",
            "region": "eu-central-1"
        },
        {
            "name": "onlineDaysConference",
            "endpoint": "https://f9zpe9ih3h.execute-api.eu-central-1.amazonaws.com/mdprod",
            "region": "eu-central-1"
        },
        {
            "name": "zoomapi",
            "endpoint": "https://wq47b64q09.execute-api.eu-central-1.amazonaws.com/mdprod",
            "region": "eu-central-1"
        },
        {
            "name": "youtubeApi",
            "endpoint": "https://tnfoq4ldpl.execute-api.eu-central-1.amazonaws.com/mdprod",
            "region": "eu-central-1"
        },
        {
            "name": "teamsapi",
            "endpoint": "https://57963l03w0.execute-api.eu-central-1.amazonaws.com/mdprod",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "online-days-storage101605-mdprod",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_content_delivery_bucket": "onlinedayshosting-mdprod",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d2mrzqvmddtv3q.cloudfront.net"
};


export default awsmobile;
