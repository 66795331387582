import React, { useEffect, useState } from "react";
import Amplify, {
  API,
  Auth,
  graphqlOperation,
  Hub,
  Logger,
  I18n,
} from "aws-amplify";
import moment from "moment";
import "moment/locale/de";
import awsconfig from "./aws-exports";
import { v4 as uuidv4 } from "uuid";
import { withCookies } from "react-cookie";
import * as mutations from "./graphql/mutations";
import { getOrganization as getMainOrganization } from "./graphql/queries";
import StateProvider from "./state";
import translations from "./locale/translations";
import "./App.scss";
import german from "antd/es/date-picker/locale/de_DE";
import english from "antd/es/date-picker/locale/en_GB";

import Theme from "./frontend/theme/Theme";
import CookiePopup from "./frontend/CookiePopup";
import Loader from "./Loader";
import ability, { defineRulesFor } from "./config/ability";
import CustomAuth from "./frontend/auth/CustomAuth";

Amplify.configure(awsconfig);
Auth.configure({
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

const logger = new Logger("My-Logger");

const getUserId = `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      teamsTokens {
        accessToken
        refreshToken
        expires_in
      }
      sessions {
        items {
          session {
            id
          }
        }
      }
      events {
        items {
          event {
            id
          }
        }
      }
    }
  }
`;

function App(props) {
  const [loading, setLoading] = useState(true);
  const [userGroups, setUserGroups] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userFirstName, setUserFirstName] = useState("");
  const [language, setLanguage] = useState("de");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [consentCookie, setConsentCookie] = useState(false);
  const [antdLocale, setAntdLocale] = useState(german);
  const [globalUser, setGlobalUser] = useState();
  const [userEnrollments, setUserEnrollments] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const [globalOrganization, setGlobalOrganization] = useState({});

  const upcomingSesssionsRef = React.createRef();
  const archivedSessionsRef = React.createRef();
  const currentSessionsRef = React.createRef();

  const scrollToRef = () => {
    upcomingSesssionsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const listener = async (data) => {
    switch (data.payload.event) {
      case "signOut":
        logger.error("user signed out");
        window.location.href = "/";
        return;
      case "signIn":
        await fetchCredentials();
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    fetchCredentials();
    Hub.listen("auth", listener);
    I18n.putVocabularies(translations);
    I18n.setLanguage(language);
    moment.locale(language);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkCookie = () => {
    const { cookies } = props;
    const consentCookie = cookies.get("consentCookie");
    if (consentCookie === "consentGiven") {
      setConsentCookie(true);
    }
    if (consentCookie === "noConsent") {
      setConsentCookie(true);
    }
  };

  const fetchCredentials = async () => {
    setLoading(true);
    let {
      data: { getOrganization },
    } = await API.graphql(graphqlOperation(getMainOrganization, { id: "MD1" }));
    setGlobalOrganization(getOrganization);

    // Check if the user is authenticated
    let currentUserCredentials = await Auth.currentUserCredentials();
    let { authenticated } = currentUserCredentials;

    if (authenticated) {
      let user = await Auth.currentSession({ bypassCache: true });
      const {
        idToken: {
          payload: { sub },
        },
      } = user;

      setUserFirstName(user.idToken.payload.given_name);
      setIsAuthenticated(true);
      try {
        const {
          data: { getUser },
        } = await API.graphql(graphqlOperation(getUserId, { id: sub }));
        //Check if user in DynamoDB already exists
        if (getUser === null) {
          const { given_name, family_name, email } = user.idToken.payload;
          let input = {
            id: sub,
            firstName: given_name,
            lastName: family_name,
            email,
          };
          await API.graphql(graphqlOperation(mutations.createUser, { input }));

          // Maybe log something here
        } else if (getUser.firstName === null) {
          const { given_name, family_name, email } = user.idToken.payload;
          await API.graphql(
            graphqlOperation(mutations.updateUser, {
              input: {
                id: sub,
                firstName: given_name,
                lastName: family_name,
                email,
              },
            })
          );
        } else {
          setGlobalUser(getUser);
          setUserEnrollments(
            getUser?.sessions?.items?.map((enr) => enr?.session?.id)
          );
          setUserEvents(getUser?.events?.items?.map((e) => e?.event?.id));
        }
      } catch (e) {
        console.log(e);
      }
      let userGroup = user.accessToken.payload["cognito:groups"];
      setUserGroups(userGroup === undefined ? [] : userGroup);
      ability.update(defineRulesFor(userGroup));
      setUserId(sub);
      setLoading(false);
      // If no authenticated user
    } else {
      setUserId(currentUserCredentials.data.IdentityId || uuidv4());
      let input = {
        id: currentUserCredentials.data.IdentityId,
        firstName: "Anonymous",
        lastName: "User",
        isTemporary: true,
      };
      try {
        await API.graphql(graphqlOperation(mutations.createUser, { input }));
      } catch (err) {
        console.log("Temporary user exists");
      }

      setUserGroups([]);

      setLoading(false);
    }
  };

  const popup = () => {
    if (!consentCookie) {
      return (
        <CookiePopup
          cookies={props.cookies}
          setConsentCookie={setConsentCookie}
        />
      );
    }
    return null;
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
    I18n.setLanguage(lang);
    moment.locale(lang);
    if (lang === "de") {
      setAntdLocale(german);
    } else {
      setAntdLocale(english);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Theme>
            <StateProvider
              state={{
                userGroups,
                userId,
                setUserId,
                userFirstName,
                setLanguage: changeLanguage,
                language,
                upcomingSesssionsRef,
                currentSessionsRef,
                archivedSessionsRef,
                scrollToRef,
                isAuthenticated,
                antdLocale,
                globalUser,
                userEnrollments,
                setUserEnrollments,
                userEvents,
                setUserEvents,
                globalOrganization,
              }}
            >
              {window.location.origin.includes("test") ? (
                <CustomAuth>
                  {popup()}
                  {props.children}
                </CustomAuth>
              ) : (
                <>
                  {popup()}
                  {props.children}
                </>
              )}
            </StateProvider>
          </Theme>
        </>
      )}
    </>
  );
}

export default withCookies(App);
