import { Ability, AbilityBuilder } from "@casl/ability";
import { Group, Department, Program, Session, Module } from "../types/Types";

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

export function defineRulesFor(group = []) {
  let replacedGroups = group.map((g) => g.replace(/_/g, " "));
  const { can, cannot, rules } = new AbilityBuilder();
  if (group.includes("staff")) {
    can("view", ["", "/sessions", "/modules", "/me", "/logout", "/backend"]);
  }
  if (group.includes("departmentEditors")) {
    can("visit", "event");
    can("view", [
      "",
      "/departments",
      "/programs",
      "/sessions",
      "/modules",
      "/users",
      "/me",
      "/logout",
      "/backend",
    ]);
    cannot("read", "userDetails");
    can("delete", "user");

    can("read", Group, {
      groupName: { $in: group },
    });
    cannot("create", "Group");
    can("create", "Session");
    can("create", "Module");
    cannot(["read", "delete"], Group, {
      groupName: { $in: ["visitors", "admins", "staff"] },
    });
    can("read", Group, { groupName: { $in: ["staff"] } });
    cannot("read", "Export");
    cannot("read", "Analytics");
    can("read", Department, { title: { $in: replacedGroups } });
    can("read", Program, { department: { $in: replacedGroups } });
    can("read", Session, { department: { $in: replacedGroups } });
    can("read", Module, { department: { $in: replacedGroups } });
  }
  if (group.includes("admins")) {
    can("manage", "all");
    cannot("delete", Group, {
      groupName: { $in: ["visitors", "admins", "staff", "departmentEditors"] },
    });
  }

  return rules;
}

export default ability;
